
@media (min-width: 300px) and (max-width: 768px) {
    .hidden {
        opacity: 0;
        transition: opacity 0.5s ease, transform 0.5s ease;
        display: none;
    }
    
    .visible {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 0.5s ease, transform 0.5s ease;
        display: inline-block;
    }
    .container {
        display: flex;
        flex-direction: row;
    }
    
    .services {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0px 18px;
        background-color: rgba(255, 136, 0, 0.704);
    }
    .services h2 {
        margin-left: 0px;
        text-align: center;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 2.3em;
        color: white;
    }
    .services h3 {
        margin-top: -20px;
        padding: 10px;
        font-size: 1.1em;
        color: black;
    }
    .card1, .card2 {
        flex: 1;
        min-width: 40vw;
        min-height: 50vw;
        max-height: 40vw;
        max-width: 40vw;
        font-family: 'GeosansLight', sans-serif;
        margin-left: 12px;
        padding-top: 45px;
        background-color: rgb(0, 0, 0);
        border-radius: 20px;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.53);
        text-align: center;
        cursor: pointer;
        display: inline-block;
        position: static;
        transition: 1s all;
        transform: 0.3s ease;
        background-repeat: no-repeat;
        opacity: 0.5;
        z-index: 2;
    }
    .card1 a, .card2 a {
        text-decoration: none;
    }
    .card1 h1, .card2 h1 {
        margin-top: -160px;
        margin-bottom: 30px;
        padding: 0px;
        color: rgb(255, 255, 255);
        border-radius: 50px;
        font-weight: 100;
        text-transform: uppercase;
    }
    .card1 {
        margin-top: 100px;
        opacity: 0.7;
        background-image: url(../components/icons/capadesignnail.jpg);
        background-size: cover;
        background-position: center;
    }
    .card1 h3 {
        font-family: 'GeosansLight', sans-serif;
        font-size: 24px;
        margin-top: -40px;
    }
    
    .card1:hover, .card2:hover, .card3:hover {
        transform: translateY(-5px);
        box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.534);
        opacity: 1;
    }
    .card2 {
        margin-top: 100px;
        margin-left: 20px;
        background-image: url(../components/icons/capadesignbrow.png);
        background-size: cover;
        background-position: center;
    }
    .card2 h3 {
        font-size: 26px;
        margin-top: -40px;
        margin-left: -7px;
    }
    #text-curso {
        margin-top: -70px;
        font-family: "Geosanslight", sans-serif;
        text-align: center;
        width: 90vw;
        color: white;
        transition: 0.5s linear;
        z-index: 1;
    }
    #text-curso h1, #text-curso2 h1 {
        font-size: 28px;
    }
    
    #text-curso h3, #text-curso2 h3 {
        font-size: 22px;
    }
    
    #txt1 {
        padding-top: 100px;
        max-width: 400px;
    }
    #txt2 {
        margin: 0 50px;
    }
    p {
        font-size: 24px;
    }
    #text-curso h2 {
        font-family: "Geosanslight", sans-serif;
        margin-top: -20px;
        margin-bottom: -20px;
        color: black;
    }
    #text-curso2 {
        margin-top: -70px;
        font-family: "Geosanslight", sans-serif;
        text-align: center;
        width: 90vw;
        color: white;
        transition: 0.5s linear;
        z-index: 1;
    }
    #text-curso2 h2 {
        font-family: "Geosanslight", sans-serif;
        margin-top: -20px;
        margin-bottom: -20px;
        color: black;
    }
    #texto_chamada {
        text-align: center;
        padding: 30px;
        margin-top: -50px;
    }
    #texto_chamada h3 {
        font-size: 30px ;
        color: white;
    }
    #texto_chamada h2 {
        font-weight: 900;
        color: white;
    }
    #Inscrever3 {
        font-family: "Montserrat", sans-serif;
        background: linear-gradient(to top left, gold, orange, rgb(255, 106, 0));
        color: #fff;
        padding: 10px 20px;
        border: none;
        border-radius: 40px;
        box-shadow: 1px 1px 5px #000;
        text-shadow: 0 10px 15px rgba(0, 0, 0, 0.534);
        font-size: 28px;
        cursor: pointer;
        margin-top: -100px;
        margin-bottom: -200px;
        display: inline-block;
        transform: 1s ease;
        transition: 0.5s all;
    }
    #Inscrever3:hover {
        color: #000;
        cursor: default;
        transform: translateY(-7px);
        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.534);
    }
    .item1 {
        margin-left: 130px;
        margin-top: -50px;
        grid-column: 2; /* Coluna 2 */
        grid-row: 8; /* Linha 2 */
    }
    #clique2 {
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: 600;
        font-size: 1em;
        margin-left: 15px;
        width: 250px;
        height: 60px;
        background: linear-gradient(to top left, gold, orange, rgb(255, 106, 0));
        text-shadow: 0 10px 15px rgba(0, 0, 0, 0.534);
        color: black;
        cursor: pointer;
        border-radius: 30px;
        transition: 0.5s all;
        transform: 1s ease;
    }
    #clique2:hover {
        display: inline-flexbox;
        color: black;
        transform: translateY(-5px);
        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.534);
        background-color: orange;
    }
}
/* DESKTOP HALF HD QUERY */
@media (min-width: 769px) and (max-width: 1366px) {
    .hidden {
        opacity: 0;
        transition: opacity 0.5s ease, transform 0.5s ease;
        display: none;
      }
      
      .visible {
        opacity: 1;
        transition: opacity 0.5s ease, transform 0.5s ease;
        display: block;
      }
    
    .services {
        height: 2900px;
        display: grid;
        grid-template-columns: repeat(3, 4fr);
        grid-template-rows: repeat(4, 1fr);
        justify-content: space-between;
        padding: 20px -20px;
        background-color: rgba(255, 136, 0, 0.704);
    }
    .services h2 {
        text-align: center;
        grid-column: 2;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 2.5em;
        color: white;
        margin-left: -400px;
    }
    .services h3 {
        margin-top: -20px;
        font-size: 1.1em;
        color: black;
    }
    .card1, .card2 {
        font-family: 'GeosansLight', sans-serif;
        flex-direction: row;
        height: 305px;
        width: 225px;
        margin: 200px 0px 0 -870px;
        background-color: rgb(0, 0, 0);
        border-radius: 20px;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.53);
        text-align: center;
        cursor: pointer;
        display: inline-block;
        transition: 1s all;
        transform: 0.3s ease;
        background-repeat: no-repeat;
        opacity: 0.5;
        z-index: 2;
    }
    .card1 h1, .card2 h1 {
        margin-top: -100px;
        padding: 0px;
        color: rgb(255, 255, 255);
        border-radius: 50px;
        font-weight: 100;
        text-transform: uppercase;
    }
    .card1 {
        grid-column: 1;
        opacity: 0.7;
        background-image: url(../components/icons/capadesignnail.jpg);
        background-size: cover;
        background-position: center;
    }
    .card1 h3 {
        font-family: 'GeosansLight', sans-serif;
        font-size: 26px;
    }
    
    .card1:hover, .card2:hover, .card3:hover {
        transform: translateY(-20px);
        box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.534);
        opacity: 1;
    }
    .card2 {
        margin-left: 620px;
        grid-column: 3;
        background-image: url(../components/icons/capadesignbrow.png);
        background-size: cover;
        background-position-y: -10px;
    }
    .card2 h3 {
        text-transform: uppercase;
        font-size: 26px;
    }
    #texto_chamada {
        width: 50%;
        margin-left: 455px;
        margin-top: 80px;
        margin-bottom: -50px;
        text-align: center;
        padding: 30px;
    }
    #texto_chamada h3 {
        font-size: 30px ;
        color: white;
    }
    #texto_chamada h2 {
        font-weight: 900;
        color: white;
        margin-left: 0px;
    }
    #text-curso {
        font-family: "Geosanslight", sans-serif;
        text-align: center;
        grid-column: 1/4;
        margin-top: -270px;
        margin-left: -20px;
        color: white;
        transition: 0.3s ease-in;
        z-index: 1;
    }
    #text-curso h1, #text-curso2 h1 {
        font-size: 28px;
    }
    
    #text-curso h3, #text-curso2 h3 {
        font-size: 22px;
    }
    
    #txt1 {
        margin-top: -90px;
        margin-left: 370px;
        max-width: 650px;
    }
    #txt2 {
        margin: 0 200px;
    }
    #txt2 h2 {
        margin-left: -15px;
    }
    p {
        font-size: 24px;
    }
    #text-curso h2 {
        font-family: "Geosanslight", sans-serif;
        margin-top: -20px;
        margin-bottom: -20px;
        color: black;
    }
    #text-curso2 {
        font-family: "Geosanslight", sans-serif;
        text-align: center;
        grid-column: 1/4;
        margin-top: -270px;
        margin-left: -20px;
        color: white;
        transition: 0.7s ease-in-out;
        z-index: 1;
    }
    #text-curso2 h2 {
        font-family: "Geosanslight", sans-serif;
        margin-top: -20px;
        margin-bottom: -20px;
        color: black;
    }
    #Inscrever3 {
        font-family: "Montserrat", sans-serif;
        background: linear-gradient(to top left, gold, orange, rgb(255, 106, 0));
        color: #fff;
        padding: 10px 20px;
        border: none;
        border-radius: 40px;
        box-shadow: 1px 1px 5px #000;
        text-shadow: 0 10px 15px rgba(0, 0, 0, 0.534);
        font-size: 28px;
        cursor: pointer;
        margin-top: -100px;
        margin-bottom: -200px;
        display: inline-block;
        transform: 1s ease;
        transition: 0.5s all;
    }
    #Inscrever3:hover {
        color: #000;
        cursor: default;
        transform: translateY(-7px);
        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.534);
    }
    .item1 {
        margin-left: -40px;
        margin-top: 80px;
        grid-column: 2; /* Coluna 2 */
        grid-row: 8; /* Linha 2 */
    }
    #clique2 {
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: 600;
        font-size: 1em;
        margin-left: 15px;
        width: 250px;
        height: 60px;
        background: linear-gradient(to top left, gold, orange, rgb(255, 106, 0));
        text-shadow: 0 10px 15px rgba(0, 0, 0, 0.534);
        color: black;
        cursor: pointer;
        border-radius: 30px;
        transition: 0.5s all;
        transform: 1s ease;
    }
    #clique2:hover {
        display: inline-flexbox;
        color: black;
        transform: translateY(-5px);
        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.534);
        background-color: orange;
    }
}

/* DESKTOP QUERY */
@media (min-width: 1367px) and (max-width: 1920px) {
    .hidden {
        opacity: 0;
        transition: opacity 0.5s ease, transform 0.5s ease;
        display: none;
      }
      
      .visible {
        opacity: 1;
        transition: opacity 0.5s ease, transform 0.5s ease;
        display: block;
      }
    
    .services {
        height: 2650px;
        display: grid;
        grid-template-columns: repeat(3, 4fr);
        grid-template-rows: repeat(4, 1fr);
        justify-content: space-between;
        padding: 20px 100px;
        background-color: rgba(255, 136, 0, 0.704);
    }
    .services h2 {
        text-align: center;
        grid-column: 2;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 3em;
        color: white;
        margin-left: 0px;
        margin-top: 0px;
    }
    .services h3 {
        margin-top: -20px;
        font-size: 1.1em;
        color: black;
    }
    .card1, .card2 {
        font-family: 'GeosansLight', sans-serif;
        height: 450px;
        width: 332px;
        margin: 200px 0px 0 -1000px;
        background-color: rgb(0, 0, 0);
        border-radius: 20px;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.53);
        text-align: center;
        cursor: pointer;
        display: inline-block;
        transition: 1s all;
        transform: 0.3s ease;
        background-repeat: no-repeat;
        opacity: 0.5;
        z-index: 99;
    }
    .card1 a, .card2 a {
        text-decoration: none;
    }
    .card1 h1, .card2 h1 {
        margin-top: -100px;
        padding: 0px;
        color: rgb(255, 255, 255);
        border-radius: 50px;
        font-weight: 100;
        text-transform: uppercase;
    }
    .card1 {
        grid-column: 1;
        opacity: 0.7;
        background-image: url(../components/icons/capadesignnail.jpg);
        background-size: cover;
        background-position: center;
    }
    .card1 h3 {
        font-family: 'GeosansLight', sans-serif;
        font-size: 26px;
    }
    
    .card1:hover, .card2:hover, .card3:hover {
        box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.534);
        opacity: 1;
    }
    .card2 {
        margin-left: 800px;
        grid-column: 3;
        background-image: url(../components/icons/capadesignbrow.png);
        background-size: cover;
        background-position-y: -10px;
    }
    .card2 h3 {
        text-transform: uppercase;
        font-size: 26px;
    }
    #texto_chamada {
        text-align: center;
        margin-right: -1110px;
        margin-bottom: -130px;
        margin-top: -20px; 
        padding: 30px;
    }
    #texto_chamada h3 {
        font-size: 30px ;
        margin-bottom: 5px;
        font-weight: 200;
        color: white;
        text-decoration: underline;
    }
    #texto_chamada h2 {
        font-weight: 900;
        color: white;
    }
    #text-curso {
        font-family: "Geosanslight", sans-serif;
        text-align: center;
        grid-column: 1/4;
        margin-top: -430px;
        color: white;
        transition: 0.3s ease-in;
        z-index: 1;
    }
    #text-curso h1, #text-curso2 h1 {
        font-size: 28px;
    }
    
    #text-curso h3, #text-curso2 h3 {
        font-size: 22px;
    }
    
    #txt1 {
        margin-top: -90px;
        margin-left: 525px;
        max-width: 650px;
    }
    #txt2 {
        margin: 0 250px;
    }
    #txt2 h2 {
        margin-left: -15px;
    }
    p {
        font-size: 24px;
    }
    #text-curso h2 {
        font-family: "Geosanslight", sans-serif;
        margin-top: -20px;
        margin-bottom: -20px;
        color: black;
    }
    #text-curso2 {
        font-family: "Geosanslight", sans-serif;
        text-align: center;
        grid-column: 1/4;
        margin-top: -430px;
        color: white;
        transition: 0.7s ease-in-out;
        z-index: 1;
    }
    #text-curso2 h2 {
        font-family: "Geosanslight", sans-serif;
        margin-top: -20px;
        margin-bottom: -20px;
        color: black;
    }
    #Inscrever3 {
        font-family: "Montserrat", sans-serif;
        background: linear-gradient(to top left, gold, orange, rgb(255, 106, 0));
        color: #fff;
        padding: 10px 20px;
        border: none;
        border-radius: 40px;
        box-shadow: 1px 1px 5px #000;
        text-shadow: 0 10px 15px rgba(0, 0, 0, 0.534);
        font-size: 28px;
        cursor: pointer;
        margin-top: -100px;
        margin-bottom: -200px;
        display: inline-block;
        transform: 1s ease;
        transition: 0.5s all;
    }
    #Inscrever3:hover {
        color: #000;
        cursor: default;
        transform: translateY(-7px);
        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.534);
    }
    .item1 {
        margin-left: -40px;
        margin-top: 80px;
        grid-column: 2; /* Coluna 2 */
        grid-row: 8; /* Linha 2 */
    }
    #clique2 {
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: 600;
        font-size: 1em;
        margin-left: 15px;
        width: 250px;
        height: 60px;
        background: linear-gradient(to top left, gold, orange, rgb(255, 106, 0));
        text-shadow: 0 10px 15px rgba(0, 0, 0, 0.534);
        color: black;
        cursor: pointer;
        border-radius: 30px;
        transition: 0.5s all;
        transform: 1s ease;
    }
    #clique2:hover {
        display: inline-flexbox;
        color: black;
        transform: translateY(-5px);
        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.534);
        background-color: orange;
    }
}