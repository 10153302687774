.Contato {
    background-color: rgba(0, 0, 0, 0.506);
    text-align: center;
    padding: 0px;
    color: white;
    padding-top: 20px;
}
.Contato h3 {
    font-family: 'Geosanslight', sans-serif;
    font-size: 18px;
    letter-spacing: 2px;
}
.fone-mail a {
    font-family: 'Geosanslight', sans-serif;
    font-weight: 500;
    font-size: 16px;
    text-decoration: none;
    color: white;
}
.fone-mail hr {
    width: 50px;
}
.social-icons {
    font-family: 'Geosanslight', sans-serif;
    font-size: 18px;
    margin-top: 10px;
    letter-spacing: 2px;
    display: inline-block;
    transition: 0.5s all;
    transform: 1s ease;
}
#Facebook:hover, #Instagram:hover, #Whatsapp:hover {
    transform: translateY(-10px);
    filter: drop-shadow(2px 0px 3px rgb(255, 255, 255));
}

#Facebook, #Instagram, #Whatsapp {
    transition: 0.5s all;
    transform: 1s ease;
    padding: 0 10px 0 10px;
}