
@media (min-width: 300px) and (max-width: 768px) {
    .tconosco {
        text-align: center;
        background-color: #000000bf;
        padding: 20px;
        width: 100%;
        height: 400px;
        margin: 0 auto;
        margin-left: -20px;
    }
    .tconosco h2 {
        text-transform: uppercase;
        font-weight: 800;
        font-size: 40px;
        color: white;
        margin-bottom: 10px;
        margin-top: 20px;
    }
    .tconosco h3 {
        font-weight: 800;
        color: white;
    }
    label {
        display: block;
        margin-bottom: 8px;
        font-weight: bold;
        color: #ffffff;
    }
    
    input[type="nome1"],
    input[type="email1"] {
        text-align: center;
        padding: 8px;
        width: 60vw;
        margin-bottom: 30px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
    }
    
    button {
        background-color: orangered;
        transition: color 1s all;
        color: #fff;
        padding: 10px 15px;
        border: 2px white;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
    }
}


/* DESKTOP HALF HD QUERY */
@media (min-width: 769px) and (max-width: 1366px) {
    
    .tconosco {
        text-align: center;
        background-color: #000000bf;
        padding: 20px;
        width: 100%;
        height: 400px;
        margin: 0 auto;
        margin-left: -25px;
    }
    .tconosco h2 {
        text-transform: uppercase;
        font-weight: 800;
        font-size: 40px;
        color: white;
        margin-bottom: 10px;
        margin-top: 20px;
    }
    .tconosco h3 {
        font-weight: 800;
        color: white;
    }
    label {
        display: block;
        margin-bottom: 8px;
        font-weight: bold;
        color: #ffffff;
    }
    
    input[type="nome1"],
    input[type="email1"] {
        text-align: center;
        padding: 8px;
        width: 30vw;
        margin-bottom: 30px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
    }
    
    button {
        background-color: orangered;
        transition: color 1s all;
        color: #fff;
        padding: 10px 15px;
        border: 2px white;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
    }
    
    button:hover {
        background-color: orange;
    }
}

/* DESKTOP FULL HD QUERY */
@media (min-width: 1367px) and (max-width: 1920px) {

    .tconosco {
        text-align: center;
        background-color: #000000bf;
        padding: 20px;
        width: 100%;
        height: 400px;
        margin: 0 auto;
        margin-left: -25px;
    }
    .tconosco h2 {
        text-transform: uppercase;
        font-weight: 800;
        font-size: 40px;
        color: white;
        margin-bottom: 10px;
        margin-top: 20px;
    }
    .tconosco h3 {
        font-weight: 800;
        color: white;
    }
    label {
        display: block;
        margin-bottom: 8px;
        font-weight: bold;
        color: #ffffff;
    }
    
    input[type="nome1"],
    input[type="email1"] {
        text-align: center;
        padding: 8px;
        width: 30vw;
        margin-bottom: 30px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
    }
    
    button {
        background-color: orangered;
        transition: color 1s all;
        color: #fff;
        padding: 10px 15px;
        border: 2px white;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
    }
    
    button:hover {
        background-color: orange;
    }
}