@media (min-width: 300px) and (max-width: 768px) {
    #beneficio {
        margin: 0 auto;
        margin-top: 25px;
        margin-bottom: 80px;
        display: flex;
        flex-direction: column; /* Exibe os itens um abaixo do outro */
        border-radius: 50px;
        border: 1px solid #ff6f00;
        border-radius: 50px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
        background-color: #000000df;
        height: 100%;
        width: 100%;
        justify-content: center;
    }
    #saibamais {
        color: white;
        padding-bottom: 50px;
        margin-bottom: 50px;
    }
    .benefit-item {
        display: flex;
        align-items: center; /* Alinha a imagem com o texto na mesma linha */
        text-align: left;
        margin-left: 20px;
        margin-bottom: -5px; /* Espaçamento entre as linhas de texto */
        font-family: 'Geosanslight', sans-serif;
    }

    .benefit-item img {
        margin-right: 15px; /* Espaço entre a imagem e o texto */
        width: 36px; /* Ajuste o tamanho da imagem conforme necessário */
        height: auto; /* Mantém a proporção da imagem */
    }
    #beneficio h2 {
        margin-top: 20px;
        margin-bottom: 5px;
        font-family: 'Geosanslight', sans-serif;
        font-size: 35px;
        margin-left: 0px;
        color: white;
    }
    .benefit-item h3 {
        margin: 0;
        color: white;
    }
    .price-btn {
        width: 98%;
        height: 40px;
        margin: 0 auto;
        text-align: center;
        color: #ff6f00;
        border-radius: 50px;
        border: 1px solid #ff6f00;
        border-radius: 50px;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0);
        background-color: #000000df;
    }
    .price-btn p {
        margin: 0 auto;
        margin-top: 5px;
    }
    .price {
        text-align: center;
        margin-top: 20px;
    }
    .price h3, h2 {
        color: white;
    }
    #Inscrever4 {
        background-color: #ff6f00;
        color: #fff;
        padding: 20px 25px;
        margin-top: -20px;
        margin-bottom: 5px;
        border-width: 3px;
        border-color: black;
        border-radius: 20px;
        cursor: pointer;
        font-size: 16px;
        transition: 0.5s linear;
    }
    #Inscrever4:hover {
        background-color: orange;
    }


}
/* DESKTOP HALF HD QUERY */
@media (min-width: 769px) and (max-width: 1366px) {
    #beneficio {
        margin-bottom: -50px;
        margin-left: 180px;
        display: flex;
        flex-direction: column; /* Exibe os itens um abaixo do outro */
        border-radius: 50px;
        border: 1px solid #ff6f00;
        border-radius: 50px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
        background-color: #000000df;
        height: 100%;
        width: 420px;
        justify-content: center;
    }
    .item2 {
        display: flex;
        flex-direction: row;
    }
    #saibamais {
        color: white;
    }
    .benefit-item {
        display: flex;
        align-items: center; /* Alinha a imagem com o texto na mesma linha */
        text-align: left;
        margin-left: 20px;
        margin-bottom: 10px; /* Espaçamento entre as linhas de texto */
        font-family: 'Geosanslight', sans-serif;
    }
    
    .benefit-item img {
        margin-right: 15px; /* Espaço entre a imagem e o texto */
        width: 24px; /* Ajuste o tamanho da imagem conforme necessário */
        height: auto; /* Mantém a proporção da imagem */
    }
    #beneficio h2 {
        margin-top: 10px;
        margin-bottom: 10px;
        font-family: 'Geosanslight', sans-serif;
        font-size: 35px;
        margin-left: 0px;
        color: white;
    }
    .benefit-item h3 {
        margin: 0;
        color: white;
    }
    .price-btn {
        width: 98%;
        height: 40px;
        margin: 0 auto;
        text-align: center;
        color: #ff6f00;
        border-radius: 50px;
        border: 1px solid #ff6f00;
        border-radius: 50px;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0);
        background-color: #000000df;
    }
    .price-btn p {
        margin: 0 auto;
        margin-top: 5px;
    }
    .price {
        text-align: center;
        margin-top: 30px;
    }
    .price h3, h2 {
        color: white;
    }
    #Inscrever4 {
        background-color: #ff6f00;
        color: #fff;
        padding: 20px 25px;
        margin-bottom: 15px;
        border-width: 10px;
        border-color: black;
        border-radius: 20px;
        cursor: pointer;
        font-size: 16px;
        transition: 0.5s linear;
    }
    #Inscrever4:hover {
        background-color: orange;
    }
}

/* DESKTOP QUERY */
@media (min-width: 1367px) and (max-width: 1920px) {
    #beneficio {
        margin-top: 15px;
        margin-right: -280px;
        margin-left: 330px;
        margin-bottom: 25px;
        display: flex;
        flex-direction: column; /* Exibe os itens um abaixo do outro */
        border-radius: 50px;
        border: 1px solid #ff6f00;
        border-radius: 50px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
        background-color: #000000df;
        height: 600px;
        width: 480px;
        justify-content: center;
    }
    .item2 {
        display: flex;
        flex-direction: row;
    }
    #saibamais {
        color: white;
    }
    .benefit-item {
        display: flex;
        align-items: center; /* Alinha a imagem com o texto na mesma linha */
        margin-left: 20px;
        margin-bottom: 10px; /* Espaçamento entre as linhas de texto */
        font-family: 'Geosanslight', sans-serif;
    }
    
    .benefit-item img {
        margin-right: 15px; /* Espaço entre a imagem e o texto */
        width: 24px; /* Ajuste o tamanho da imagem conforme necessário */
        height: auto; /* Mantém a proporção da imagem */
    }
    #beneficio h2 {
        margin-top: 0px;
        margin-bottom: 20px;
        font-family: 'Geosanslight', sans-serif;
        font-size: 35px;
        margin-left: 0px;
        color: white;
        z-index: 3;
    }
    .benefit-item h3 {
        margin: 0;
        text-align: left;
        color: white;
    }
    .price-btn {
        width: 98%;
        height: 40px;
        margin: 0 auto;
        text-align: center;
        color: #ff6f00;
        border-radius: 50px;
        border: 1px solid #ff6f00;
        border-radius: 50px;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0);
        background-color: #000000df;
    }
    .price-btn p {
        margin: 0 auto;
        margin-top: 5px;
    }
    .price {
        text-align: center;
        margin-top: 30px;
    }
    .price h3, h2 {
        color: white;
    }
    #Inscrever4 {
        background-color: #ff6f00;
        color: #fff;
        padding: 20px 25px;
        margin-bottom: 15px;
        border-radius: 20px;
        border-width: 10px;
        border-color: black;
        cursor: pointer;
        font-size: 16px;
        transition: 0.5s linear;
    }
    #Inscrever4:hover {
        background-color: orange;
        cursor: pointer;
    }
}