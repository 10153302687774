.Creditos { 
    display: block;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.76);
    padding: 5px;
}
.Creditos h3 {
    font-family: 'Geosanslight', sans-serif;
    font-weight: 200;
    font-size: 17px;
    letter-spacing: 2px;
}
.Creditos a {
    text-decoration: none;
    color: white;
}