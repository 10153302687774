#topo {
    background-image: url(../components/icons/topicon.png);
    background-size: cover;
    width: 60px;
    height: 60px;
    font-family: 'GeosansLight', sans-serif;
    position: fixed;
    right: 20px;
    bottom: 10px;
    z-index: 3;
    display: inline-block;
    filter: drop-shadow(1px 1px 1px black);
    transition: 1s ease-in-out;
}  
#topo:hover {
    transform: translateY(-7px);
}