/* styles/Main.css */
@import url('https://fonts.cdnfonts.com/css/geo-sans-light');

@media (min-width: 300px) and (max-width: 768px) {
  body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: 'Geosanslight', sans-serif;
  }
  .videobg {
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.5);
    z-index: -1;
  }
  .parallax {
    position: relative;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  main {
    font-family: 'Geosanslight', sans-serif;
    text-align: center;
    color: white;
    height: 26vh;
  }
  main p {
    font-weight: 500;
    font-size: 25px;
    margin-top: -100px;
    padding: 20px;
    z-index: 1;
    text-shadow: 2px 2px 5px black;
  }
  #logobg {
    margin-top: 60px;
    width: 40vh;
    height: 40vh;
    filter: drop-shadow(5px 0 10px rgb(0, 0, 0));
    display: inline-block;
    transition: 1s all;
    transform: 1s ease;
  }
}

/* DESKTOP HALF HD QUERY */
@media (min-width: 769px) and (max-width: 1366px) {
  body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  
  .parallax {
    position: relative;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  
  .videobg {
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.5);
    z-index: -1;
  }
  
  main {
    font-family: 'Geosanslight', sans-serif;
    text-align: center;
    color: white;
    margin-top: 200px;
    height: 40vh;
  }
  main p {
    font-weight: 500;
    font-size: 25px;
    margin-top: -90px;
    padding: 20px;
    z-index: 1;
    text-shadow: 2px 2px 5px black;
  }
  .content {
    margin-top: -230px;
    height: 30vw;
  }
  #logobg {
    margin-top: 0;
    width: 50vh;
    height: 50vh;
    filter: drop-shadow(5px 0 10px rgb(0, 0, 0));
    display: inline-block;
    transition: 1s all;
    transform: 1s ease;
  }
  #logobg:hover {
    animation: gradientText 2s ease-in-out;  /* Aplica a animação de gradiente */
    transform: translateY(-20px);
}

@keyframes gradientText {
    0% {
        filter: drop-shadow(5px 0 10px rgb(0, 0, 0));/* Sombra de texto com efeito degradê */
    }
    50% {
        filter: drop-shadow(7px 10px 20px rgb(0, 0, 0));/* Sombra de texto com efeito degradê */
    }
    100% {
      filter: drop-shadow(5px 0 10px rgb(0, 0, 0));/* Sombra de texto com efeito degradê */
  }
}
  /* SOURCE BTN           
  
          <a href="https://wa.me/send?phone=5519978167330&text=Olá!%20Eu%20gostaria%20de%20saber%20mais%20informações%20sobre%20um%20curso.">
            <button id="clique">Quero transformar minha vida!</button>
          </a>
          */
  #clique {
    margin-top: -25px;
    font-family: 'Geosanslight', sans-serif;
    font-weight: 600;
    font-size: 1.5em;
    text-shadow: 2px 2px 3px black;
    width: 330px;
    height: 50px;
    background-color: rgb(255, 157, 0);
    color: rgb(255, 255, 255);
    cursor: pointer;
    display: inline-block;
    border-radius: 50px;
    transition: 0.5s all;
    transform: 1s ease;
    z-index: 1;
    opacity: 0.8;
  }
  
  #clique:hover {
    color: rgb(0, 0, 0);
    transform: translateY(-15px);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.534);
    background-color: gold;
    opacity: 0.8;
  }
}

/* DESKTOP FUL HD QUERY */
@media (min-width: 1367px) and (max-width: 1920px) {
  body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  
  .parallax {
    position: relative;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  
  .videobg {
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.5);
    z-index: -1;
  }
  
  main {
    font-family: 'Geosanslight', sans-serif;
    text-align: center;
    color: white;
    margin-top: 200px;
    height: 40vh;
  }
  main p {
    font-weight: 500;
    font-size: 25px;
    margin-top: -130px;
    padding: 20px;
    z-index: 1;
    text-shadow: 2px 2px 5px black;
  }
  .content {
    margin-top: -230px;
    height: 30vw;
  }
  #logobg {
    margin-top: 0;
    width: 50vh;
    height: 50vh;
    filter: drop-shadow(5px 0 10px rgb(0, 0, 0));
    display: inline-block;
    transition: 1s all;
    transform: 1s ease;
  }
  #logobg:hover {
    animation: gradientText 2s ease-in-out;  /* Aplica a animação de gradiente */
    transform: translateY(-20px);
}

@keyframes gradientText {
    0% {
        filter: drop-shadow(5px 0 10px rgb(0, 0, 0));/* Sombra de texto com efeito degradê */
    }
    50% {
        filter: drop-shadow(7px 10px 20px rgb(0, 0, 0));/* Sombra de texto com efeito degradê */
    }
    100% {
      filter: drop-shadow(5px 0 10px rgb(0, 0, 0));/* Sombra de texto com efeito degradê */
  }
}
  /* SOURCE BTN           
  
          <a href="https://wa.me/send?phone=5519978167330&text=Olá!%20Eu%20gostaria%20de%20saber%20mais%20informações%20sobre%20um%20curso.">
            <button id="clique">Quero transformar minha vida!</button>
          </a>
          */
  #clique {
    margin-top: -25px;
    font-family: 'Geosanslight', sans-serif;
    font-weight: 600;
    font-size: 1.5em;
    text-shadow: 2px 2px 3px black;
    width: 330px;
    height: 50px;
    background-color: rgb(255, 157, 0);
    color: rgb(255, 255, 255);
    cursor: pointer;
    display: inline-block;
    border-radius: 50px;
    transition: 0.5s all;
    transform: 1s ease;
    z-index: 1;
    opacity: 0.8;
  }
  
  #clique:hover {
    color: rgb(0, 0, 0);
    transform: translateY(-15px);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.534);
    background-color: gold;
    opacity: 0.8;
  }
}