/* ContactForm.css */

@media (min-width: 300px) and (max-width: 768px) {
    .form {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        width: 88.5vw;
        height: 550px;
        margin: 70px 50px 20px -145px;
        padding: 20px;
        border-radius: 50px;
        border: 1px solid #ff6f00;
        border-radius: 50px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
        background-color: #000000df;
    }
    
    .input {
        font-family: 'Geosans Light', sans-serif;
        font-weight: bold;
        width: 95%;
        padding: 10px;
        margin: 10px 0;
        text-align: center;
        border-radius: 20px;
        border: 1px solid #ff6f00;
    }
    
    .label {
        color: rgb(255, 255, 255);
        text-shadow: 1px 1px 0px rgb(0, 0, 0);
        margin-bottom: 5px;
        font-weight: bold;
        border-radius: 20px;
    }
    
    .button {
        font-weight: 600;
        font-size: 1em;
        margin-top: 0px;
        width: 250px;
        height: 60px;
        background: linear-gradient(to top left, gold, orange, rgb(255, 106, 0));
        box-shadow: 0 10px 25px rgba(0, 0, 0, 0.534);
        color: black;
        cursor: pointer;
        border-radius: 30px;
        transition: 0.5s all;
        transform: 1s ease;
        border: 1px solid #ff6f00;
    }
    
    .button:hover {
        color: white;
        transform: translateY(-10px);
        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.534);
    }
    
    .textarea {
        height: 90px;
        resize: none;
    }
}
/* DESKTOP HALF HD QUERY */
@media (min-width: 769px) and (max-width: 1366px) {
    .form {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        width: 550px;
        height: 550px;
        margin: -50px 0px 50px -315px;
        padding: 20px;
        border: 1px solid #ff6f00;
        border-radius: 50px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
        background-color: #000000df;
    }
    .form::before {
        filter: blur(15px);
        background-color: #00000021;
        z-index: -1; /* coloca o pseudoelemento atrás do conteúdo */
    }
    
    .input {
        font-family: 'Geosans Light', sans-serif;
        font-weight: bold;
        width: 90%;
        padding: 10px;
        margin: 10px 0;
        text-align: center;
        border-radius: 20px;
        border: 1px solid #ff6f00;
        box-sizing: border-box;
        background-color: #ffffffe6;
        z-index: 1;
    }
    
    .label {
        color: rgb(255, 255, 255);
        text-shadow: 1px 1px 0px rgb(0, 0, 0);
        margin-bottom: 5px;
        font-weight: bold;
        z-index: 1;
    }
    
    .button {
        font-weight: 600;
        font-size: 1em;
        margin-top: 20px;
        width: 250px;
        height: 60px;
        background: linear-gradient(to top left, gold, orange, rgb(255, 106, 0));
        box-shadow: 0 10px 25px rgba(0, 0, 0, 0.534);
        color: black;
        cursor: pointer;
        border-radius: 30px;
        border: 1px solid #ff6f00;
        transition: 0.5s all;
        transform: 1s ease;
        z-index: 1;
    }
    
    .button:hover {
        color: white;
        transform: translateY(-10px);
        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.534);
        z-index: 1;
    }
    
    .textarea {
        z-index: 1;
        height: 80px;
        resize: none;
    }
}

/* DESKTOP QUERY */
@media (min-width: 1367px) and (max-width: 1920px) {
    .form {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        width: 550px;
        height: 550px;
        margin: -10px 0px 50px 30px;
        padding: 10px;
        border: 1px solid #ff6f00;
        border-radius: 50px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
        background-color: #000000df;
    }
    .form::before {
        filter: blur(15px);
        background-color: #00000021;
        z-index: -1; /* coloca o pseudoelemento atrás do conteúdo */
    }
    
    .input {
        font-family: 'Geosans Light', sans-serif;
        font-weight: bold;
        width: 90%;
        padding: 10px;
        margin: 10px 0;
        text-align: center;
        border-radius: 20px;
        border: 1px solid #ff6f00;
        box-sizing: border-box;
        background-color: #ffffffe6;
        z-index: 1;
    }
    
    .label {
        color: rgb(255, 255, 255);
        text-shadow: 1px 1px 0px rgb(0, 0, 0);
        margin-bottom: 5px;
        font-weight: bold;
        z-index: 1;
    }
    
    .button {
        font-weight: 600;
        font-size: 1em;
        margin-top: 20px;
        width: 250px;
        height: 60px;
        background: linear-gradient(to top left, gold, orange, rgb(255, 106, 0));
        box-shadow: 0 10px 25px rgba(0, 0, 0, 0.534);
        color: black;
        cursor: pointer;
        border-radius: 30px;
        border: 1px solid #ff6f00;
        transition: 0.5s all;
        transform: 1s ease;
        z-index: 1;
    }
    
    .button:hover {
        color: white;
        transform: translateY(-10px);
        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.534);
        z-index: 1;
    }
    
    .textarea {
        z-index: 1;
        height: 80px;
        resize: none;
        overflow-y: hidden;
    }
}