/* Menu Toggle Button */
.menu-toggle {
  display: none;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
}

@media (min-width: 300px) and (max-width: 728px) {
  header {
    top: 0;
    position: fixed;
    height: 65px;
    background-color: #000;
    width: 100%;
    z-index: 9999;
  }
  #logo img {
    width: 100px;
    height: 100px;
    margin-bottom: -10px;
  }
  #check {
      display: none;
  }
  #headerlabel {
      margin-top: -100px;
      right: 20px;
      font-size: 3em;
      position: fixed;
      color: rgba(255, 255, 255, 1);
  }
  nav ul {
    font-family: 'GeosansLight', sans-serif;
    font-size: 1.3em;
    list-style: none;
    background-color: rgba(0, 0, 0, 0.91);
    position: fixed;
    text-align: center;
    padding: 20px;
    height: 100vh;
    top: 45px;
    right: -460px;
    width: 100vw;
    transition: all 0.5s;
    z-index: 2;
  }

    /* Submenu escondido por padrão */
    .submenu {
      opacity: 0; /* Começa invisível */
      line-break: auto;
      visibility: hidden; /* Submenu escondido */
      position: absolute;
      text-align: center;
      flex-direction: column;
      background-color: rgb(0, 0, 0);
      padding: 10px 10px;
      font-size: 18px;
      width: 90%;
      top: 0px; /* Aparece logo abaixo do item principal */
      border-radius: 5px;
      z-index: 99;
      transition: opacity 0.5s ease-in, visibility 0.5s ease-in;
    }

    .submenu.open {
      opacity: 1; /* Visível */
      visibility: visible; /* Mostra o submenu */
      margin: 0 auto;
      width: 90%;
      top: 100px;
      left: -450px;
    }
    
    .submenu.closed {
      opacity: 0; /* Invisível */
      visibility: hidden; /* Esconde o submenu */
      left: -1000px; /* Mova para fora da tela se necessário */
    }

    .submenu li {
      margin: 10px 0;
    }

    .submenu li a {
      color: #fff;
      text-decoration: none;
    }

    .submenu li a:hover {
      color: #06A1B4;
    }


  #submenubtn {
    font-family: 'Geosanslight', sans-serif;
    font-size: 20px;
    background-color: rgba(0, 0, 0, 0);
    text-decoration: none;
    color: white;
    border-radius: 0;
    border-bottom: 2px solid gold;
    margin-bottom: -10px;
    padding: 8px 8px;
    cursor: pointer;
  }

  nav a {
      text-decoration: none;
      color: white;
      margin: 5px;
      transition: color 0.3s;
  }
  #check:checked ~ul {
      right: -30px;
  }
  nav ul li {
      padding: 20px;
  }
  nav ul li a {
      text-decoration: none;
      color: white;
      padding: 10px;
      border-bottom: 2px solid gold;
  }
  .line {
    margin-top: -35px;
    height: 6.5px;
    background: linear-gradient(to top left, gold, orange, chocolate);
    opacity: 1;
  }
  .menu-toggle {
      display: block;
      margin: 10px auto;
  }

  .mobile-menu ul {
      display: flex;
      flex-direction: column;
      align-items: center;
  }
  #btn-social {
    font-size: 20px;
    font-family: "GeosansLight", sans-serif;
    background-color: #00000000;
    color: white;
    border-color: #00000000;
    margin-top: -13px;
    padding-bottom: 17px;
    padding-right: 20px;
    display: inline-block;
    transform: 1s ease;
    transition: 0.8s all;
    border-bottom: 2px solid gold;
  }
  #btn-social:hover {
    color: #f6ff00;
    transform: translateY(-5px);
  }
  .whatsapp-icon, .facebook-icon, .instagram-icon {
    padding-bottom: 10px;
    border-bottom: 2px solid white;
    border-radius: 5px;
  }
  #social-icons img:first-child {
    padding-left: 18px;
  }
}

/* DESKTOP HALF HD QUERY */
@media (min-width: 769px) and (max-width: 1366px) {
      header {
        background-color: black;
        width: 100vw;
        height: 60px;
    }
    .Container {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
    }
    /* LOGO */
    #logo img {
        margin-top: -18px;
        margin-left: 30;
        width: 125px;
        height: 125px;
    }
    .hidden {
        opacity: 0;
        transform: translateX(-20px);
        transition: opacity 0.5s ease, transform 0.5s ease;
      }
      
      .visible {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 0.5s ease, transform 0.5s ease;
      }

              /* Estilos padrão do submenu */
        /* Para Desktop: mostrar o submenu abaixo do item principal ao clicar */
        .submenu {
          display: flex; /* Para layout */
          flex-direction: column;
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.3s ease, visibility 0.3s ease;
          position: absolute;
          top: 33px;
          right: 100;
          background-color: rgb(0, 0, 0);
          padding: 10px 20px;
          width: 150px;
          border-radius: 5px;
          z-index: 99;
      }
      
      .submenu.open {
          opacity: 1;
          visibility: visible;
      }    

      .main-menu > li {
        position: relative;
      }
    
      /* Exibe o submenu ao passar o mouse no item principal */
      .main-menu > li:hover .submenu {
        opacity: 1;
        visibility: visible;
      }

      /* Mostra o submenu ao passar o mouse */
      .menu-item:hover .submenu {
        display: block;
      }

      /* Estilo dos itens do submenu */
      .submenu li {
        padding: 5px;
        margin-left: -10px;
      }

      .submenu li a {
        color: #fff;
        text-decoration: none;
      }

      .submenu li a:hover {
        color: #f6ff00; /* Exemplo de cor de hover */
      }

        #submenubtn {
          font-family: 'Geosanslight', sans-serif;
          font-size: 20px;
          background-color: rgba(0, 0, 0, 0);
          border-radius: 155px;
          border-color: #00000000;
          color: #fff;
          padding: 0px 8px;
          cursor: pointer;
          display: inline-block;
          transition: 0.9s all;
          transform: 1s ease;
        }
        
        #submenubtn:hover {
          color: #f6ff00;
          transform: translateY(-5px);
        }

      #btn-social {
        font-size: 20px;
        font-family: "GeosansLight", sans-serif;
        background-color: #00000000;
        color: white;
        border-color: #00000000;
        margin-top: -13px;
        padding-bottom: 17px;
        padding-right: 20px;
        display: inline-block;
        transform: 1s ease;
        transition: 0.8s all;
        border-right: 2px solid gold;
      }
      #btn-social:hover {
        color: #f6ff00;
        transform: translateY(-5px);
      }
      
      #social-icons img {
        width: 25px;
        height: 25px;
        padding: 5px;
        display: inline-block;
        justify-content: space-between;
        margin: -7px 9px;
        color: #000; /* Mude a cor conforme necessário */
        transform: 1s ease-out;
        transition: 0.8s all;
      }
      #social-icons img:first-child {
        padding-left: 5px;
      }
      #social-icons img:hover {
        filter: drop-shadow(5px 5px 1px rgba(255, 255, 255, 0.296));
        transform: translateY(-9px);
      }
      
    .line {
        margin-top: -57px;
        height: 6.5px;
        background: linear-gradient(to top left, gold, orange, chocolate);
        opacity: 1;
    }
    .desktop-menu {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    #headerlabel {
        display: none;
    }
    #check {
      display: none;
    }
    nav ul {
        list-style: none;
        display: flex;
        justify-content: center;
        position: static;
        width: 90vw;
        height: auto;
        background-color: transparent;
        margin-top: 25px;
        padding-left: 250px;
    }
    nav ul li {
        font-family: "GeosansLight", sans-serif;
        font-size: 20px;
        font-weight: 400;
        margin-right: 30;
    }
    nav ul li:last-child {
        margin-right: 17px;
    }
    nav ul li a {
        padding: 0 5px;
        text-decoration: none;
        color: white;
        display: inline-block;
        transform: 1s ease;
        transition: 0.8s all;
    }
    nav ul li a:hover {
        color: #f6ff00;
        transform: translateY(-5px);
    }

}
/* DESKTOP FULL HD QUERY */
@media (min-width: 1367px) and (max-width: 1920px) {

      header {
          background-color: black;
          height: 60px;
      }
      .Container {
          display: grid;
          grid-template-columns: repeat(6, 1fr);
      }
      /* LOGO */
      #logo img {
          margin-top: -13px;
          margin-left: 50px;
          width: 125px;
          height: 125px;
      }
      .hidden {
          opacity: 0;
          transform: translateX(-20px);
          transition: opacity 0.5s ease, transform 0.5s ease;
        }
        
        .visible {
          opacity: 1;
          transform: translateX(0);
          transition: opacity 0.5s ease, transform 0.5s ease;
        }
        #btn-social {
          font-size: 20px;
          font-family: "GeosansLight", sans-serif;
          background-color: #00000000;
          color: white;
          border-color: #00000000;
          margin-top: -13px;
          padding-bottom: 17px;
          padding-right: 20px;
          display: inline-block;
          transform: 1s ease;
          transition: 0.8s all;
          border-right: 2px solid gold;
        }
        #btn-social:hover {
          color: #f6ff00;
          transform: translateY(-5px);
        }
        
        #social-icons img {
          width: 25px;
          height: 25px;
          padding: 5px;
          display: inline-block;
          justify-content: space-between;
          margin: -7px 9px;
          color: #000; /* Mude a cor conforme necessário */
          transform: 1s ease-out;
          transition: 0.8s all;
        }
        #social-icons img:first-child {
          padding-left: 5px;
        }
        #social-icons img:hover {
          filter: drop-shadow(5px 5px 1px rgba(255, 255, 255, 0.296));
          transform: translateY(-9px);
        }
        
        /* Estilos padrão do submenu */
        /* Para Desktop: mostrar o submenu abaixo do item principal ao clicar */
        .submenu {
          display: flex; /* Para layout */
          flex-direction: column;
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.3s ease, visibility 0.3s ease;
          position: absolute;
          top: 33px;
          right: 100;
          background-color: rgb(0, 0, 0);
          padding: 10px 20px;
          width: 150px;
          border-radius: 5px;
          z-index: 99;
      }
      
      .submenu.open {
          opacity: 1;
          visibility: visible;
      }    

      .main-menu > li {
        position: relative;
      }
    
      /* Exibe o submenu ao passar o mouse no item principal */
      .main-menu > li:hover .submenu {
        opacity: 1;
        visibility: visible;
      }

      /* Mostra o submenu ao passar o mouse */
      .menu-item:hover .submenu {
        display: block;
      }

      /* Estilo dos itens do submenu */
      .submenu li {
        padding: 5px;
        margin-left: -10px;
      }

      .submenu li a {
        color: #fff;
        text-decoration: none;
      }

      .submenu li a:hover {
        color: #f6ff00; /* Exemplo de cor de hover */
      }

        #submenubtn {
          font-family: 'Geosanslight', sans-serif;
          font-size: 20px;
          background-color: rgba(0, 0, 0, 0);
          border-radius: 155px;
          border-color: #00000000;
          color: #fff;
          padding: 0px 8px;
          cursor: pointer;
          display: inline-block;
          transition: 0.9s all;
          transform: 1s ease;
        }
        
        #submenubtn:hover {
          color: #f6ff00;
          transform: translateY(-5px);
        }

      .line {
          margin-top: -57px;
          height: 6.5px;
          background: linear-gradient(to top left, gold, orange, chocolate);
          opacity: 1;
      }
      .desktop-menu {
          display: flex;
          flex-direction: row;
          justify-content: center;
      }
      #headerlabel {
          display: none;
      }
      #check {
        display: none;
      }
      nav ul {
          list-style: none;
          display: flex;
          justify-content: center;
          position: static;
          width: 1100px;
          height: auto;
          background-color: transparent;
          margin-top: 25px;
          padding-left: 700px;
      }
      nav ul li {
          font-family: "GeosansLight", sans-serif;
          font-size: 20px;
          font-weight: 400;
          margin-right: 30;
      }
      nav ul li:last-child {
          margin-right: 17px;
      }
      nav ul li a {
          padding: 0 5px;
          text-decoration: none;
          color: white;
          display: inline-block;
          transform: 1s ease;
          transition: 0.8s all;
      }
      nav ul li a:hover {
          color: #f6ff00;
          transform: translateY(-5px);
      }

}