
@media (min-width: 300px) and (max-width: 768px) {
    .sobre {
        font-family: 'Geosanslight', sans-serif;
        padding: 60px;
        color: #fff;
        grid-column: 1/6;
        grid-row: 1/6;
    }
    .sobre h2 {
        margin-top: -50px;
        font-weight: 800;
        font-size: 3em;
        padding: 5px;
        text-transform: uppercase;
        text-shadow: 2px 2px 3px black;
        margin: 0 auto;
    }
    .textoSobre {
        font-size: 1.5em;
        font-weight: 400;
        text-indent: center;
        margin: 0 auto;
        text-align: center;
        position: relative;
        transition: transform 1s ease, left 1s ease;
    }
    
    iframe {
        margin: 10px;
        border: 0;
        border-radius: 100px;
    }
    section {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(5, 1fr);
        background-color: rgb(0, 0, 0);
        height: 100%;
        text-align: center;
    }
    
    #clique {
        margin-left: -15px;
        margin-top: 40px;
        font-family: 'Geosanslight', sans-serif;
        font-weight: 600;
        font-size: 1.3em;
        text-shadow: 2px 2px 3px rgba(31, 31, 31, 0.582);
        width: 80vw;
        height: 50px;
        background: linear-gradient(to top left, gold, orange, rgb(255, 106, 0));
        color: white(0, 0, 0);
        cursor: pointer;
        display: inline-block;
        border-radius: 50px;
        transition: 0.4s all;
        transform: 1s ease;
        z-index: 1;
        opacity: 1;
    }
      
    
    .fade-in-section {
        opacity: 1;
        transform: translateY(20px);
        transition: opacity 1s ease-out, transform 1s ease-out;
        will-change: opacity, transform;
    }
    .fade-in-section.is-visible {
        opacity: 1;
        transform: none;
    }
}
/* DESKTOP HALF HD QUERY */
@media (min-width: 769px) and (max-width: 1366px) {
    .sobre {
        font-family: 'Geosanslight', sans-serif;
        padding: 0px 200px;
        color: #fff;
        grid-column: 1/6;
        grid-row: 1/6;
    }
    .sobre h2 {
        font-weight: 800;
        font-size: 3em;
        padding: 5px;
        text-transform: uppercase;
        text-shadow: 2px 2px 3px black;
    }
    .textoSobre {
        font-size: 1.5em;
        font-weight: 400;
        text-indent: center;
        text-align: center;
        position: relative;
        transition: transform 1s ease, left 1s ease;
    }
    
    /* <iframe width="640" height="395" src="https://www.youtube.com/embed/BBz_3MzXyPM?si=owQfpuSCVxQZUlvH" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen id="videosobre"></iframe> */
    #videosobre {
        margin: 20px;
    }
    section {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(5, 1fr);
        background-color: rgb(0, 0, 0);
        height: 100%;
        padding-bottom: 50px;
        text-align: center;
    }

    iframe {
        margin: 10px;
        border: 0;
        border-radius: 100px;
    }

    #clique {
        margin-top: 40px;
        font-family: 'Geosanslight', sans-serif;
        font-weight: 600;
        font-size: 1.5em;
        text-shadow: 2px 2px 3px rgba(31, 31, 31, 0.582);
        width: 380px;
        height: 50px;
        background: linear-gradient(to top left, gold, orange, rgb(255, 106, 0));
        color: white(0, 0, 0);
        cursor: pointer;
        display: inline-block;
        border-radius: 50px;
        transition: 0.4s all;
        transform: 1s ease;
        z-index: 1;
        opacity: 1;
      }
      
      #clique:hover {
        color: black(255, 255, 255);
        transform: translateY(-5px);
        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.534);
        background: linear-gradient(to top right, gold, orange, rgb(255, 106, 0));
        opacity: 1;
      }
    
    .fade-in-section {
        opacity: 1;
        transform: translateY(20px);
        transition: opacity 1s ease-out, transform 1s ease-out;
        will-change: opacity, transform;
    }
    .fade-in-section.is-visible {
        opacity: 1;
        transform: none;
    }
}

/* DESKTOP QUERY */
@media (min-width: 1367px) and (max-width: 1920px) {
    .sobre {
        font-family: 'Geosanslight', sans-serif;
        padding: 0px 500px;
        color: #fff;
        grid-column: 1/6;
        grid-row: 1/6;
    }
    .sobre h2 {
        font-weight: 800;
        font-size: 3em;
        padding: 5px;
        text-transform: uppercase;
        text-shadow: 2px 2px 3px black;
    }
    .textoSobre {
        font-size: 1.5em;
        font-weight: 400;
        text-indent: center;
        text-align: center;
        position: relative;
        transition: transform 1s ease, left 1s ease;
    }
    
    iframe {
        margin: 10px;
        border: 0;
        border-radius: 100px;
    }
    section {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(5, 1fr);
        background-color: rgb(0, 0, 0);
        height: 100%;
        padding-bottom: 50px;
        text-align: center;
    }
    
    #clique {
        margin-top: 40px;
        font-family: 'Geosanslight', sans-serif;
        font-weight: 600;
        font-size: 1.5em;
        text-shadow: 2px 2px 3px rgba(31, 31, 31, 0.582);
        width: 330px;
        height: 50px;
        background: linear-gradient(to top left, gold, orange, rgb(255, 106, 0));
        color: white(0, 0, 0);
        cursor: pointer;
        display: inline-block;
        border-radius: 50px;
        transition: 0.4s all;
        transform: 1s ease;
        z-index: 1;
        opacity: 1;
      }
      
      #clique:hover {
        color: black(255, 255, 255);
        transform: translateY(-5px);
        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.534);
        background: linear-gradient(to top right, gold, orange, rgb(255, 106, 0));
        opacity: 1;
      }
    
    .fade-in-section {
        opacity: 1;
        transform: translateY(20px);
        transition: opacity 1s ease-out, transform 1s ease-out;
        will-change: opacity, transform;
    }
    .fade-in-section.is-visible {
        opacity: 1;
        transform: none;
    }
}